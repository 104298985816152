import React, { useRef, useState } from 'react'
import './App.css';

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/performance'

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyBIfTV8O-_Wxvyy4SeJi-venKKwg0iwKq0",
  authDomain: "astro-superchat.firebaseapp.com",
  projectId: "astro-superchat",
  storageBucket: "astro-superchat.appspot.com",
  messagingSenderId: "346742589669",
  appId: "1:346742589669:web:330491860423104b65ac0c",
  measurementId: "G-SCRZ88K605"
})

const auth = firebase.auth();
const firestore = firebase.firestore();

export default function App() {
  const [user] = useAuthState(auth);

  return (
    <>
      <RoomBrowser />
      <div className="App">
        <header className="App-header">
          AstroChatv1
          <SignOut />
        </header>
        <section>
          {user ? <ChatRoom /> : <SignIn />}
        </section>

      </div>
    </>

  );

}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <button onClick={signInWithGoogle}>Sign in with Google</button>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function ChatRoom() {

  const dummy = useRef();

  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(50);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault(); // prevent page refresh

    const { uid, photoURL } = auth.currentUser;

    if (formValue.length !== 0) {
      await messagesRef.add({
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        photoURL
      })
      setFormValue('');
    }

    dummy.current.scrollIntoView({ behavior: 'smooth' });

  }

  return (
    <div id="ChatBox">
      <div>
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <br />
        <div ref={dummy}></div>
      </div>

      <br /> <br /> <br /> <br /> <br />

      <form onSubmit={sendMessage}>

        <input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="say something nice" maxlength={250} />
        <button type="submit">Send</button>

      </form>
    </div>
  )
}

function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL} alt="Profile" rel="preload" />
      <p>{text}</p>
    </div>
  )
}

function RoomBrowser() {
  return (
    <div class="flex-container">
      <div class="roombrowser">
        <h1 class="roombrowserheader">Room Browser</h1>

        <br /><br /><br /><br />

        <div class="roomtab">
          <h2>Coming soon!</h2>
          <div />
        </div>
      </div>
    </div>

  )
}